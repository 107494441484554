// @flow 
import * as React from 'react';
import {getUser, UserInterface} from "../../utils/user";
import CoordinadorXuaiiGeneral from "./Roles/CoordinadorXuaiiGeneral";
import DirectiveXuaiiGeneral from "./Roles/Directive/DirectiveXuaiiGeneral";
import TribeXuaii from "./TribeXuaii";

type Props = {};
const XuaiiContainerGeneral = (props: Props) => {
    //GetUser===========================================================>
    const [user, setUser] = React.useState<UserInterface>({
        roles: "",
        username: "",
        id: 0
    });
    React.useEffect(() => {
        const user: UserInterface = getUser();
        setUser(user);
    }, []);
    //GetUser <===========================================================
    const RenderComponent = {
        "Coordinador": <CoordinadorXuaiiGeneral/>,
        "Directive": <DirectiveXuaiiGeneral/>
    }
    return (
        <>
            {

                RenderComponent[user?.roles as keyof typeof RenderComponent] ? (
                        RenderComponent[user?.roles as keyof typeof RenderComponent]
                    ) :
                    <TribeXuaii/>
            }
        </>
    );
};

export default XuaiiContainerGeneral;