import {SET_DATA_STUDENT_XUAII,SAVE_PREVIOUS_STATE,SET_TABLE_DATA,SET_COMPETENCY_DATA,SET_ENGLISH} from "../actions/type";
import {QuestionType,competencyDataXuaii} from "../../views/xuaii/types";
export interface StudentData {
    topic: string;
    correct_performance: number;
    incorrect_performance: number;
}
export interface TypeStudentFilter {
    [key:string]:[StudentData],
}
export interface TypeStudentFilterQuestion {
    [key:string]:[QuestionType],
}
export interface TypeStudentFilterCompetency {
    [key:string]:[competencyDataXuaii],
}
export interface TypeStudentFilterEnglish {
    [key:string]:[{}],
}


export interface TypeStudentIdQuestion {
    [key:number]:TypeStudentFilterQuestion
}
export interface TypeStudentId {
    [key:number]:TypeStudentFilter
}
export interface TypeStudentIdCompetency{
    [key:number]:TypeStudentFilterCompetency
}
export interface TypeStudentIdEnglish{
    [key:number]:TypeStudentFilterEnglish
}



export interface AppState {
    studentData: {
        [key: number]: TypeStudentId;
    };
    previousStudentData: {
        [key: number]: StudentData;
    };
    loader: boolean;
    dataTables:{
        [key: number]: TypeStudentIdQuestion;
    };
    competency:{
        [key: number]: TypeStudentIdCompetency;
    }
    english:{
        [key: number]: TypeStudentIdEnglish;
    }
}

export const initialState: AppState = {
    studentData: {},
    previousStudentData: {},
    loader: false,
    dataTables:{},
    competency:{},
    english:{}
};


export const studentXuaiiReducer = (state = initialState, action:any)=>{
    switch (action.type) {
        case SET_DATA_STUDENT_XUAII:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    [action.identifier]: {
                        ...state.studentData[action.identifier],
                        [action.year]: {
                            ...(state.studentData[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload,
                        },
                    },
                },
                loader: true,
            };
        case SET_TABLE_DATA:
            return {
                ...state,
                dataTables: {
                    ...state.dataTables,
                    [action.identifier]: {
                        ...state.dataTables[action.identifier],
                        [action.year]: {
                            ...(state.dataTables[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload,
                        },
                    },
                },
                loader: true,
            };
        case SET_COMPETENCY_DATA:
            return {
                ...state,
                competency: {
                    ...state.competency,
                    [action.identifier]: {
                        ...state.competency[action.identifier],
                        [action.year]: {
                            ...(state.competency[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
                loader: true,
            };
        case SET_ENGLISH:
            return {
                ...state,
                english: {
                    ...state.english,
                    [action.identifier]: {
                        ...state.english[action.identifier],
                        [action.year]: {
                            ...(state.english[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
                loader: true,
            };
        case SAVE_PREVIOUS_STATE:
            return {
                ...state,
                previousStudentData: state.studentData,
            };
        default:
            return state;
    }
};