import * as React from "react";
import "../../Style/Eva/TravelGuide/Tribes.css";
import "./MenuFilter.scss";
import {Row, Col, Card, FormCheck} from "react-bootstrap";
import Chart from "react-apexcharts";
import bar from "./chart/bar-chart";
import barStats from "./chart/bar-chart-Stats";
import barInstitions from "./chart/bar-chart-institution";

import radar from "./chart/radar";
import Tribe from "../Pihe/Tribe";
import Cookies from "../Pihe/cookies";
import ListTribes from "./Components/ListTribes";
import {UserInterface, getToken, getUser} from "../../utils/user";
import {useEffect, useState} from "react";
import {api} from "../../utils/api";
import TribeCoordi from '../Coordinador/TribeCoordi';
import NomandsCoordi from "../Coordinador/NomandsCoordi";
import Nomand from "../Teacher/NomandsTeacher";
import TribeDirective from "../Directives/TribeDirective";
import InformesGroup from '../Pihe/Informes_group';
//new Design===================================================>
import NavFilter from "./tribe_Components/NavFilter";
//new Design <==================================================
import XuaiiContainerG from "../xuaii/XuaiiContainerG";
import TribeTeacher from "../Teacher/TribeTeacher";
import TribeData from "../Buscando el tesoro/Reports/Tribe-data";
import TribeTesoro from "../Buscando el tesoro/Reports/Tribe-data";
import SultanContainerG from "../El Engaño del Sultán/Tribes/SultanContainerG";
import XuaiiContainerGeneral from "../xuaii/XuaiiContainerGeneral";
import SultanContainerGeneral from "../El Engaño del Sultán/General/SultanContainerGeneral";

const General = () => {


    const temp = getT();


    function getT() {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(`t=`)) {
                return cookie.substring("t".length + 1, cookie.length);
            }
        }
    }

    const componentRender = {
        // "Coordinador": <TribeCoordi/>,
        // "DDirectivo": <TribeDirective/>,
        // "PIHE": <InformesGroup/>,
        "Buscando a Xuaii": <XuaiiContainerGeneral/>,
        "El Engaño del Sultán": <SultanContainerGeneral />

    }

    return (
        <>
            {
                componentRender[getT() as keyof typeof componentRender] ? (
                        componentRender[getT() as keyof typeof componentRender]
                    ) :
                    <>
                        {
                            <XuaiiContainerGeneral />
                        }
                    </>

            }

        </>
    );
};
export default General;
