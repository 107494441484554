import {
    SET_AREA_COORDI,
    SET_COMPETENCY_COORDI,
    SET_ENGLISH_COORDI
} from "../actions/type";
import {
    AreaDataXuaiiGrupal,
    CompetencyDataXuaiiGrupalCoordi,
    worldviewXuaii
} from "../../views/xuaii/types";

export interface TypeCoordiFilter {
    [key:string]:[AreaDataXuaiiGrupal],
}

export interface TypeCoordiFilterCompetency {
    [key:string]:[CompetencyDataXuaiiGrupalCoordi],
}
export interface TypeCoordiFilterEnglish {
    [key:string]:worldviewXuaii,
}

export interface TypeCoorditId {
    [key:number]:TypeCoordiFilter
}
export interface TypeCoordiIdCompetency{
    [key:number]:TypeCoordiFilterCompetency
}
export interface TypeCoordiIdEnglish{
    [key:number]:TypeCoordiFilterEnglish
}



export interface AppState {
    areaCoordi: {
        [key: number]: TypeCoorditId;
    };

    competencyCoordi:{
        [key: number]: TypeCoordiIdCompetency;
    };
    englishCoordi:{
        [key: number]: TypeCoordiIdEnglish;
    }
}

export const initialState: AppState = {
    areaCoordi:{},
    competencyCoordi:{},
    englishCoordi:{}
};


export const XuaiiCoordiReducer = (state = initialState, action:any)=>{
    switch (action.type) {
        case SET_AREA_COORDI:
            return {
                ...state,
                areaCoordi: {
                    ...state.areaCoordi,
                    [action.identifier]: {
                        ...state.areaCoordi[action.identifier],
                        [action.year]: {
                            ...(state.areaCoordi[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        case SET_COMPETENCY_COORDI:
            return {
                ...state,
                competencyCoordi: {
                    ...state.competencyCoordi,
                    [action.identifier]: {
                        ...state.competencyCoordi[action.identifier],
                        [action.year]: {
                            ...(state.competencyCoordi[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        case SET_ENGLISH_COORDI:
            return {
                ...state,
                englishCoordi: {
                    ...state.englishCoordi,
                    [action.identifier]: {
                        ...state.englishCoordi[action.identifier],
                        [action.year]: {
                            ...(state.englishCoordi[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        default:
            return state;
    }
};
