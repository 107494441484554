import * as React from "react";
import { lazy, Suspense, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import routesOnePage from "../route";
import routes from "../routes";
import { Redirect } from "react-router-dom";
import Config from "../config";
import { api } from "../utils/api";
import {toast} from "react-hot-toast";
import {Card} from "react-bootstrap";
import gifFlip from "../assets/gif/flip-cel.gif";
import '../Style/App/App.scss';
const AdminLayout = lazy(() => import("./layout/AdminLayout"));


const App = (props: any) => {

    const [isHorizontal, setIsHorizontal] = React.useState(false);


    useEffect(() => {
        function checkOrientation() {
            setIsHorizontal(window.innerHeight > window.innerWidth);
        }

        window.addEventListener('resize', checkOrientation);
        checkOrientation(); // Comprueba la orientación cuando se carga el componente

        return () => {
            window.removeEventListener('resize', checkOrientation);
        };
    }, []);



    const checkUserAuth = (token: string) => {
    api
      .post("auth/verify", { token })
      .then((response) => {
        const data = response.data;
        data.username = response.data.user;
        data.token = token;
      })
      .catch((error) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        props.history.push("/login");
      });
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) checkUserAuth(accessToken);
    else {

    }
  }, []);


  if (!navigator.onLine){
      toast.error("ERROR_INTERNET");
  }
  const location = useLocation();
    return (
        <>
            {isHorizontal ?
                <div className="PopRotateMobil" >
                    <img
                        src={gifFlip}
                        alt="Voltea el celular gif"
                    />
                </div>
                :
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Route path={routesOnePage.map((x) => x.path)}>
                            <Switch location={location} key={location.pathname}>
                                {routesOnePage.map((route, index) => {
                                    return route.component ? (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            render={(props: any) => <route.component {...props} />}
                                        />
                                    ) : null;
                                })}
                            </Switch>
                        </Route>
                        <Route path={routes.map((x) => x.path)}>
                            <AdminLayout />
                        </Route>
                        <Route path={'/'} exact>
                            <Redirect to={Config.defaultPath} />
                        </Route>
                    </Suspense>
                </ScrollToTop>
            }

            {/*<div className="backdrop"/>*/}
        </>
    );
};
export default App;
