// @flow
import * as React from 'react';
import {useEffect, useState} from "react";
import {api} from "../../../utils/api";
import {getToken} from "../../../utils/user";
import useCoordi from "../../Teacher/useCoordi";
import {useSelector} from "../../../store/reducer";
import {useDispatch} from "react-redux";
import {setDegree,setInstitutionFilter} from "src/redux/actions";
import {TypeInstitution} from "../types";
import {useLocation} from "react-router-dom";

const UseGeneralData = () => {

    let location = useLocation();
    let product: string;
    const cookies = document.cookie.split(';');
    for (let i = cookies.length - 1; i >= 0; i--) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('t=')) {
            product = cookie.substring('t='.length);
        }
    }

    //REDUX =====================================
    const Redux = useSelector((state) => state.DataRoles)
    const dispatch = useDispatch();
    //===========================================

    //GET INSTITUTIONS =========================
    const {
        user,
        data,
    } = useCoordi();
    //===========================================

    //TOKEN ================================
    const headers = {
        "access-token": getToken(),
    };
    //======================================

    //GROUPS==============================
    const [groupSede, setGroupSede] = useState([
        {
            id: 0,
            name: "",
            number: 0,
        },
    ]);

    const [group, setUpdateGroup] = useState([
        {
            id: 0,
            name: "",
        },
    ]);
    //======================================

    //GET GROUPS ====================================
    const getGroup = (id: number) => {
        let url = "";
        if (product === "Buscando a Drake") url = "groups/allByInstitution/29";
        else url = "groups/allByInstitution/"+id;

        api.get(url, {headers}).then((response) => {
            let data = response.data;
            let splice = data.splice(2, 1)[0]
            data.splice(0, 0, splice);
            if (Redux.degree?.id) {
                let index = data.findIndex((item: any) => {
                    return item?.id === Redux.degree?.id;
                });
                if (index > 0){
                    data.splice(index, 1);
                    data.splice(0, 0, Redux.degree);
                }else {
                    dispatch(setDegree(splice));
                }
            }else {
                dispatch(setDegree(splice));
            }
            data = data.filter((item: undefined) => item !== undefined);
            setGroupSede(data);
            console.log(data)
        });
    };

    //================================================

    //INSTITUTION NAME AND ID====================================
    const [institutions, setInstitutions] = useState<[TypeInstitution]>([
        {
            id: 0,
            name: "",
        },
    ]);
    //==========================================================

    //GET INSTITUTIONS=================================
    const getInstitutionsOptions = () => {
        if (user?.id) {
            api
                .get("institution/organization/userId/" + user.id, {headers})
                .then((response) => {
                    const data = response.data;
                    if (location.pathname === '/data/general' && user?.roles === 'Directive')  data.push({name: 'Todas las sedes', id: 0});

                    if (Redux.institution?.id) {
                        let index = data.findIndex((item: any) => {
                            return item?.id === Redux.institution?.id;
                        });
                        data.splice(index, 1);
                        data.splice(0, 0, Redux.institution);
                    }
                    if (user?.roles === 'Directive' &&  location.pathname === '/data/general' && !Redux.institution?.id){
                        data.splice(0, 0, {name: 'Selecciona una sede', id: 999999});
                    }
                    setInstitutions(data);
                });
        }
    };
    //=================================================

    //SET INSTITUTION=================================================
    const [institution, setInstitution] = useState({
        name: "",
        id: 0,
    });
    //================================================================

    //UPDATE GROUP IF IS COORDI==================================
    useEffect(() => {

        if (data.roles !== "Mentor" && data?.institution?.id !== 0 && data.roles !== "Directive") {
            getGroup(data?.institution?.id);
        } else if (data.roles === "Directive") {
            getInstitutionsOptions();
        }
    }, [data])
    //==========================================================

    useEffect(() => {

        if (institution?.id != 0 && institution?.id !== 999999) {
            getGroup(institution?.id);
            dispatch(setInstitutionFilter(institution));
        }
        else if (Redux?.institution?.id){
            getGroup(Redux?.institution?.id);
        }
        else if (user?.roles == "Directive" && institutions[0].id !== 0) {
            getGroup(institutions[0].id);
        }
    }, [institutions, institution])

    //DEGREE SELECT / CURRENT ============================
    const [degree, setGegree] = useState();
    //=====================================================

    useEffect(() => {
        if (degree !== undefined) dispatch(setDegree(degree));
    }, [degree]);

    return {
        groupSede,
        setGegree,
        institutions,
        setInstitution,
        setUpdateGroup
    }
};

export default UseGeneralData;