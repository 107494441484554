export const SET_DATA_STUDENT_XUAII = 'SET_DATA_STUDENT_XUAII';
export const SET_DATA_STUDENT_DRAKE = 'SET_DATA_STUDENT_DRAKE';
export const SAVE_PREVIOUS_STATE = 'SAVE_PREVIOUS_STATE';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_COMPETENCY_DATA = 'SET_COMPETENCY_DATA';
export const SET_ENGLISH = 'SET_ENGLISH';


export const SET_AREA_GROUP = 'SET_AREA_GROUP';
export const SET_COMPETENCY_GROUP = 'SET_COMPETENCY_GROUP';
export const SET_ENGLISH_GROUP = 'SET_ENGSET_ENGLISH_GROUPLISH';


export const SET_AREA_COORDI = 'SET_AREA_COORDI';
export const SET_AREA_GROUP_SULTAN = 'SET_AREA_GROUP_SULTAN';
export const SET_GENERAL_COORDINADOR_SULTAN = 'SET_GENERAL_COORDINADOR_SULTAN';
export const SET_COMPETENCY_COORDI = 'SET_COMPETENCY_COORDI';
export const SET_ENGLISH_COORDI = 'SET_ENGLISH_COORDI';

export const SET_ROLES = 'SET_ROLES';

export const SET_MENTOR = 'SET_STUDENTS';

export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SET_DATE_INSTITUTION_FILTER = 'SET_DATE_INSTITUTION_FILTER';
export const SET_PRODUCT = 'SET_PRODUCT';

