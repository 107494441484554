// @flow 
import * as React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import Chart from "react-apexcharts";
import bar from "../Chart/chartSultanArea";
import barWorldView from '../Chart/worldViewSultan';

// import {
//     setAreaGroup,
//     setCompetencyGroup,
//     setEnglishGroup
// } from "../../redux/actions";

import {useEffect, useState} from "react";
import { useSelector } from "src/store/reducer";
import {AreaDataXuaiiGrupal, CompetencyDataXuaiiGrupal, worldviewXuaii} from "../../xuaii/types";
import {useDispatch} from "react-redux";
import useCurrentYear from "../Nomand/Components/UseCurrentYear";
import {getUser, UserInterface} from "../../../utils/user";
import {api, apiEva, apiLocal, apiSultan, apiSultanLocal} from "../../../utils/api";
import {
    setAreaGroup,
    setCompetencyGroup,
    setEnglishGroup,
    setGeneralCoordinadorSultan,
    setGroupAreaSultan
} from "../../../redux/actions";
import {toast} from "react-hot-toast";
import OptionsFilter from "../Nomand/Components/OptionsFilter";
import FilterProgressSultan from "./Components/FilterProgressSultan";
import {AreaDataSultanGrupal, competencyDataSultanGrupal} from "./Shared/Interfaces/data.interfaces";
import {competencyDataSultan} from "../Nomand/Shared/Interfaces/data.interfaces";
import {GroupMentor} from "../Nomand/Shared/Interfaces/interfaces";

interface Props {
    idGroup?:React.SetStateAction<any>
    groupSede?:React.SetStateAction<any>
    setUpdateGroup?:React.SetStateAction<any>
    institutions?:React.SetStateAction<any>
    setCampus?:React.SetStateAction<any>
    updateGroup?:React.SetStateAction<any>
    setIndividual?:React.SetStateAction<any>
    individual?:React.SetStateAction<boolean>
    setInstitutions?:React.SetStateAction<any>
}

const TribeSultan = ({idGroup,groupSede,setUpdateGroup,institutions,setCampus,updateGroup,setIndividual,individual,setInstitutions}:Props) => {
    const test = useSelector((state) => state.SultanGroupReducer)
    const dispatch = useDispatch();

    //REDUX =====================================
    const Redux = useSelector((state) => state.DataRoles)
    //===========================================

    const {
        currentYear
    } = useCurrentYear();
    const [year,setYear] = useState<number>(0);
    const [value,setValue] = useState<string>("entry");

    //user
    const [user, setUser] = React.useState<UserInterface>();

    //Bool AREA COMPETENCY==========================================>
    const [buttonBool1,setButtonBool1] = useState<number>(0);
    //Bool AREA COMPETENCY <==========================================

    const [data,setData] = useState<[AreaDataSultanGrupal]>([
        {
            topic: "",
            percentage: 0
        }
    ]);
    const [area,setArea] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [series,setSeries] = useState<ApexAxisChartSeries>();


    //competency

    //ChartCompetency
    const [dataCompentecy, setDataCompetency] = useState<competencyDataSultanGrupal[]>([]);
    const [matematicas,SetMatematicas] = useState<ApexAxisChartSeries>();
    const [lenguaje,Setlenguaje] = useState<ApexAxisChartSeries>();
    const [ingles,SetIngles] = useState<ApexAxisChartSeries>();
    const [naturales,SetNaturales] = useState<ApexAxisChartSeries>();
    const [are4,SetAre4] = useState<ApexAxisChartSeries>();
    const [optionslenguaje,setOptionslenguaje] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsMatematica,setOptionsMatematica] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsIngles,setOptionsIngles] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsNaturales,setOptionsNaturales] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [optionsAre4,setOptionsAre4] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });

    //Worldview
    const [worldview,setWorldview] = useState<worldviewXuaii>();

    const [Listening,setListening] = useState<ApexAxisChartSeries>();
    const [general,setGeneral] = useState<ApexAxisChartSeries>();
    const [reading,setReading] = useState<ApexAxisChartSeries>();
    const [worldviewCategories,setWorldviewCategories] = useState({ options: {
            xaxis: {
                categories: ['A1','A2','B1','B2'],
                title: {
                    text: 'Nivel',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                },
            },
        },});

    const [loading,setLoading]= useState<boolean>(true);
    const [loading2,setLoading2]= useState<boolean>(true);
    const [loading3,setLoading3]= useState<boolean>();
    const [loadingText,setLoadingText] = useState('');

    const [idividualEnable,setIdividualEnable] = React.useState<boolean>(false);
    const [groupMentor, setGroupMentor] = useState<GroupMentor[]>([]);

    useEffect(()=>{
        const Year = currentYear[currentYear.length -1]
        setYear(Year);
    },[currentYear])

    useEffect(()=>{
        setLoading(true);
        setLoading2(true);
        setLoading3(true);
        setLoadingText('Sin datos disponibles.');
        if (user?.username !== undefined ){
            if (year === 0 || year === undefined) return;

            setLoadingText('Espera, cargando datos.');
            user.roles !== "Mentor" && setIdividualEnable(false);
            const params = {
                userId:  user?.id,
                type:value,
                year:year
            }

            api.get("group_mentors_user/getGroup/" + user?.id).then((response) => {
                const group = response.data;
                const groupId = group.group.id;

                setGroupMentor([{
                    id: group.group.id,
                    name: group.group.name
                }])

                //if (Object.keys(test.areaGroup).length !== 0 && test.areaGroup?.[user?.id]?.[year]?.[value] && buttonBool1 === 0 ) {
                if (
                    test.areaGroupSultan[groupId] &&
                    test.areaGroupSultan[groupId].year === year &&
                    test.areaGroupSultan[groupId].sultan === 'Sultan' &&
                    buttonBool1 === 0
                ) {
                    const data = test.areaGroupSultan[groupId].data;
                    setData(data);
                    setLoading(false);
                    // user.roles !== "Mentor" && setIdividualEnable(true);
                }
                else if (buttonBool1 === 0 ){
                    apiSultan.get("viajeros-api/summary/group/general/"+group.group.id).then((response) => {
                        const data = response.data;
                        setData(data);
                        setLoading(false);
                        dispatch(setGroupAreaSultan(data,group.group.id,year))
                    }).catch(()=>{
                        toast.error(`Este grupo no tiene datos, \nPrueba con otro`,{
                            position:"bottom-right"
                        })
                        user.roles !== "Mentor" && setIdividualEnable(false);
                        setLoadingText('Sin datos disponibles.');
                    })
                }

                // if (Object.keys(test.competencyGroup).length !== 0 && test.competencyGroup?.[user?.id]?.[year]?.[value] && buttonBool1 === 1 ) {
                if (false ) {
                    // const data = test.competencyGroup[user?.id][year][value];
                    // setDataCompetency(data);
                    // setLoading2(false);
                    // user.roles !== "Mentor" && setIdividualEnable(true);
                }
                else if (buttonBool1 === 1){
                    apiSultan.get("viajeros-api/summary/group/competences/"+group.group.id).then((response) => {
                        setDataCompetency(response.data);
                        setLoading2(false);
                    }).catch(()=>{
                        toast.error(`Este grupo no tiene datos, \nPrueba con otro`,{
                            position:"bottom-right"
                        })
                        user.roles !== "Mentor" && setIdividualEnable(false);
                        setLoadingText('Sin datos disponibles.');
                    })
                }


                // if (Object.keys(test.englishGroup).length !== 0 && test.englishGroup?.[user?.id]?.[year]?.[value] && buttonBool1 === 2 ) {
                if (false ) {
                    // const data = test.englishGroup[user?.id][year][value];
                    // setWorldview(data);
                    // setLoading(false);
                    // user.roles !== "Mentor" && setIdividualEnable(true);
                }
                else if (buttonBool1 === 2){
                    apiSultan.get('viajeros-api/summary/group/competences/worldview/detail/'+group.group.id).then((dataWorldview)=> {
                        setWorldview((dataWorldview.data))
                    }).catch(()=>{
                        setLoading(true);
                        setLoadingText('Sin datos disponibles.');
                    })
                }

            })



        }

    },[user,value,year,buttonBool1])


    useEffect(()=>{
        const Data = [];
        const categorie:any = [];

        for (const item of data) {
            Data.push(Math.round(item.percentage));
            categorie.push(item.topic);
        }
        const dataSeries:ApexAxisChartSeries =[
            {name:"%",data: Data}
        ]

        setArea(()=>({
            options: {
                xaxis: {
                    categories: categorie,
                    title:{
                        text: 'Área',
                        style:{
                            fontWeight: 200,
                            fontSize: '15px',

                        }
                    }
                }
            }
        }))
        setSeries(dataSeries)

    },[data])

    useEffect(()=>{
        const processData = (area:string, data:React.SetStateAction<any>, competency:string, setState:React.SetStateAction<any>, optionsState:React.SetStateAction<any>) => {
            const values = [];
            const competencyValues = [];

            for (const item of data) {
                if (item.topic === area) {
                    values.push(Math.round(item.percentage));
                    competencyValues.push(item.competence);
                }
            }

            const series = [{ name: area, data: values }];
            setState(series);
            optionsState({
                options: {
                    xaxis: {
                        categories: competencyValues,
                        title: {
                            text: 'Competencia',
                            style:{
                                fontWeight: 200,
                                fontSize: '15px',

                            }
                        },
                    },
                },
            });
        };

        const areas = ['Matemáticas', 'Lenguaje', 'Inglés', 'Ciencias naturales', 'Competencias ciudadanas'];
        const stateVariables = [
            [SetMatematicas, setOptionsMatematica],
            [Setlenguaje, setOptionslenguaje],
            [SetIngles, setOptionsIngles],
            [SetNaturales, setOptionsNaturales],
            [SetAre4, setOptionsAre4],
        ];

        areas.forEach((area, index) => {
            const [setState, optionsState] = stateVariables[index];
            processData(area, dataCompentecy, "", setState, optionsState);
        });
    },[dataCompentecy])

    useEffect(() => {

        const user: UserInterface = getUser();
        if (user.roles !== 'Coordinador') setUser(user);
    }, []);

    //COORDINADOR ROL ====================>
    useEffect(() => {
        if (idGroup != undefined){
            setUser({
                roles:"Coordinador",
                username: idGroup[0]?.user?.fullName,
                id: idGroup[0]?.user?.id
            });
        }
    ;
    }, [idGroup]);
    //COORDINADOR ROL <====================

    useEffect(()=>{

        const stateVariables = {
            "listening": setListening,
            "general":setGeneral,
            "reading":setReading,
        };
        for (const key in worldview) {
            let value = worldview[key]
            let series = [];
            let state = stateVariables[key as keyof typeof stateVariables];

            for (const valueKey in value) {
                series.push(value[valueKey])
            }
            state([{name:key,data:series}])
        }

    },[worldview])

    return (
        <>
            <FilterProgressSultan
                setYear={setYear}
                setValue={setValue}
                groupSede={groupSede}
                setUpdateGroup={setUpdateGroup}
                institutions={institutions}
                setCampus={setCampus}
                groupMentor={groupMentor}
            />
            <div className="BodyXuaiiTribe" id="">
            <OptionsFilter
                style="Left-Nav"
                setButtonBool1={setButtonBool1}
                english={true}
                year ={year}
                value ={value}
            />

            <Col >
                {
                    buttonBool1 == 0?
                        series?(
                            <>

                                <br/>
                                <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                                    <Card.Header className="text-center"><h5>Porcentaje de aciertos alcanzado grupal</h5></Card.Header>
                                    <Card.Body>
                                        {loading && loadingText}
                                        {!loading &&
                                            <Chart
                                                {...bar}
                                                series={series}
                                                options={{
                                                    ...bar.options,
                                                    ...area.options,
                                                }}
                                            />
                                        }
                                    </Card.Body>
                                </Card>

                                <br/>
                                {




                                }

                            </>
                        ):
                        <></>
                    :<></>

                }


                {
                    buttonBool1 == 1?
                    <>
                        <br/>
                        <Row>

                            <Col>
                                {
                                    series?(
                                            <Card  className="shadow-none " style={{maxWidth: 300 ,minWidth:400, marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Matemáticas</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={matematicas}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionsMatematica.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                            <Col>
                                {
                                    lenguaje?(
                                            <Card  className="shadow-none" style={{maxWidth: 300 ,minWidth:400 ,marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Lenguaje</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={lenguaje}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionslenguaje.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    optionsNaturales.options.xaxis.categories.length>0?(
                                            <Card  className="shadow-none" style={{maxWidth: 300 ,minWidth:400  ,marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Ciencias Naturales</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={naturales}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionsNaturales.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                            <Col>
                                {
                                    optionsAre4.options.xaxis.categories.length > 0?(
                                            <Card  className="shadow-none" style={{maxWidth: 300 ,minWidth:400  ,marginLeft:"auto", marginRight:"auto" }}>
                                                <Card.Header className="text-center"><h5>Competencias Ciudadanas</h5></Card.Header>
                                                <Card.Body>
                                                    {loading2 && loadingText}
                                                    {!loading2 &&
                                                        <Chart
                                                            {...bar}
                                                            series={are4}
                                                            options={{
                                                                ...bar.options,
                                                                ...optionsAre4.options,
                                                            }}
                                                        />
                                                    }

                                                </Card.Body>
                                            </Card>
                                        ):
                                        <></>
                                }
                            </Col>
                        </Row>
                    </>:<></>
                }
                {
                    buttonBool1 == 2?
                    <>
                        <br/>
                        <Row>
                            <Col>
                                {
                                    Listening ?
                                        <Card className="shadow-none" style={{maxWidth: 450, margin: "auto"}}>
                                            <Card.Header className="text-center"><h5>Listening</h5></Card.Header>
                                            <Card.Body>
                                                {
                                                    <Chart
                                                        {...barWorldView}
                                                        series={Listening}
                                                        options={{
                                                            ...barWorldView.options,
                                                            ...worldviewCategories.options,
                                                        }}

                                                    />
                                                }
                                            </Card.Body>
                                        </Card>
                                        : <></>

                                }
                            </Col>
                            <br/><br/>
                            <Col>
                                {
                                    reading ?

                                        <Card className="shadow-none" style={{maxWidth: 450, margin: "auto"}}>
                                            <Card.Header className="text-center"><h5>Reading</h5></Card.Header>
                                            <Card.Body>
                                                {
                                                    <Chart
                                                        {...barWorldView}
                                                        series={reading}
                                                        options={{
                                                            ...barWorldView.options,
                                                            ...worldviewCategories.options,
                                                        }}

                                                    />
                                                }
                                            </Card.Body>
                                        </Card> : <></>
                                }
                            </Col>
                            <br/><br/>
                        </Row>

                    </>:<></>
                }
                <br/>
                <br/>
            </Col>
        </div>
        </>
    );

};

export default TribeSultan;