
interface UserInterface {
  roles: string;
  username: string;
  id: number;
}

interface UserFullInterface extends UserInterface {
  email: string;
  fullName: string;
}

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return "";
};

const preserveUser = (user: UserInterface) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};


export { getToken, preserveUser, getUser };

export type { UserInterface, UserFullInterface };
