// @flow
import * as React from "react";
import "../../Style/Eva/Xp-Comfama/Xp-Comfama.scss";
import {Card, Col} from "react-bootstrap";
import useCoordi from "../Teacher/useCoordi";
import {useSelector} from "../../store/reducer";
const DashBoardCoordinador = () => {
    const {
        user,
        data
    } = useCoordi();

    let iframeToShow;

    let product;
    const cookies = document.cookie.split(';');
    for (let i = cookies.length - 1; i >= 0; i--) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('t=')) {
            product = cookie.substring('t='.length);
        }
    }
    if(product){
        switch (product) {
            case 'Buscando a Xuaii':
                iframeToShow = (
                    <iframe width="600" height="450"
                            src="https://lookerstudio.google.com/embed/reporting/e4ae873f-60e9-42dd-bf50-0eea124c3945/page/p_k4gyc9e2dd"
                            frameBorder="0"
                            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
                );
                break;
            case 'Expedicion Cerebro':
                iframeToShow = (
                    <iframe width="600" height="450"
                            src="https://lookerstudio.google.com/embed/reporting/cdf41628-5122-4ef0-b5e5-394de31373a5/page/p_lfpk5bnhjd"
                            frameBorder="0"  allowFullScreen
                            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
                );
                break;
            case 'Buscando a Drake':
                iframeToShow = (
                    <iframe width="600" height="450"
                            src="https://lookerstudio.google.com/embed/reporting/8f441480-92b1-4cc8-9a47-8ac330f07b68/page/KqsvD"
                            frameBorder="0"
                            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
                );
                break;
            default:
                iframeToShow = null;
                break;
        }
    }

    return (
        <Card id="ContainerTbable">
            {iframeToShow}
        </Card>
    );
}
export default DashBoardCoordinador;

