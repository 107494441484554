// @flow 
import * as React from 'react';
import CoordinadorSultanGeneral from "./CoordinadorSultanGeneral";
import TribeSultan from "../Tribes/TribeSultan";
import {getUser, UserInterface} from "../../../utils/user";
import DirectiveSultanGeneral from "./DirectiveSultanGeneral";

type Props = {};
const SultanContainerGeneral = (props: Props) => {
    //GetUser===========================================================>
    const [user, setUser] = React.useState<UserInterface>({
        roles: "",
        username: "",
        id: 0
    });
    React.useEffect(() => {
        const user: UserInterface = getUser();
        setUser(user);
    }, []);
    //GetUser <===========================================================
    const RenderComponent = {
        "Coordinador": <CoordinadorSultanGeneral/>,
        "Directive": <DirectiveSultanGeneral/>
    }
    return (
        <>
            {

                RenderComponent[user?.roles as keyof typeof RenderComponent] ? (
                        RenderComponent[user?.roles as keyof typeof RenderComponent]
                    ) :
                    <TribeSultan/>
            }
        </>
    );
};

export default SultanContainerGeneral;