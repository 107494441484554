import {
    SET_DATA_STUDENT_XUAII,
    SAVE_PREVIOUS_STATE,
    SET_TABLE_DATA,
    SET_COMPETENCY_DATA,
    SET_ENGLISH,
    SET_AREA_GROUP,
    SET_COMPETENCY_GROUP,
    SET_ENGLISH_GROUP,
    SET_AREA_COORDI,
    SET_COMPETENCY_COORDI,
    SET_ENGLISH_COORDI,
    SET_ROLES,
    SET_MENTOR,
    SET_DATE_FILTER,
    SET_DATE_INSTITUTION_FILTER,
    SET_PRODUCT, SET_DATA_STUDENT_DRAKE, SET_GENERAL_COORDINADOR_SULTAN, SET_AREA_GROUP_SULTAN
} from "./type";
import {AreaDataByGradeSultan} from "../../views/xuaii/types";
import {AreaDataSultanGrupal} from "../../views/El Engaño del Sultán/Tribes/Shared/Interfaces/data.interfaces";

export const setDataStudents = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_DATA_STUDENT_XUAII,
    payload,
    identifier,
    year,
    filter,
})
export const setDataStudentsDrake = (payload:any, identifier:number,year:number, month:number) => ({
    type:SET_DATA_STUDENT_DRAKE,
    payload,
    identifier,
    year,
    month,
})
export const setDataTables = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_TABLE_DATA,
    payload,
    identifier,
    year,
    filter,

})
export const setCompetency = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_COMPETENCY_DATA,
    payload,
    identifier,
    year,
    filter,
})
export const SetEnglish = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_ENGLISH,
    payload,
    identifier,
    year,
    filter,
})
export const savePreviousState = () => ({
    type: SAVE_PREVIOUS_STATE,
});



//group

export const setAreaGroup = (payload:any, identifier:number,year:number,filter:any) => ({
    type: SET_AREA_GROUP,
    payload,
    identifier,
    year,
    filter,
})
export const setCompetencyGroup = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_COMPETENCY_GROUP,
    payload,
    identifier,
    year,
    filter,
})
export const setEnglishGroup = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_ENGLISH_GROUP,
    payload,
    identifier,
    year,
    filter,
})


//Coordi
export const setAreaCoordi = (payload:any, identifier:number,year:number,filter:any) => ({
    type: SET_AREA_COORDI,
    payload,
    identifier,
    year,
    filter,
})
export const setCompetencyCoordi = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_COMPETENCY_COORDI,
    payload,
    identifier,
    year,
    filter,
})
export const setEnglishCoordi = (payload:any, identifier:number,year:number,filter:any) => ({
    type:SET_ENGLISH_COORDI,
    payload,
    identifier,
    year,
    filter,
})


export const setGeneralCoordinadorSultan = (data:AreaDataByGradeSultan, institutionId: number, year:number) => ({
    type: SET_GENERAL_COORDINADOR_SULTAN,
    institutionId,
    year,
    payload: data
})

export const setGroupAreaSultan = (data:AreaDataSultanGrupal, groupId: number, year:number) => ({
    type: SET_AREA_GROUP_SULTAN,
    groupId,
    year,
    payload: data
})

export const setRoles = (payload:any) =>({
    type:SET_ROLES,
    payload
});
export const setMentorRedux = (payload:any) =>({
    type:SET_MENTOR,
    payload
});

export const setDegree = (degree:any) =>({
    type:SET_DATE_FILTER,
    degree
});

export const setInstitutionFilter = (institution:any) =>({
    type:SET_DATE_INSTITUTION_FILTER,
    institution
});

export const SetProduct = (payload:any) => ({
    type: SET_PRODUCT,
    payload
})
