import Chart from 'react-apexcharts';
type ChartProps = React.ComponentProps<typeof Chart>;

const chartData: ChartProps = {
    height: "350",
    type: 'bar',
    options: {
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '90%',
                borderRadius:5,
                borderRadiusApplication: "end",
            }
        },
        dataLabels: {
            enabled: true,
            style:{
                fontWeight: 600,
                fontSize:'12px',
            }
        },
        colors:  ['#FFA403', '#ce4d1f', '#79B0AB', '#FF4500', '#9370DB', '#20B2AA', '#FF6347', '#7B68EE', '#32CD32', '#FFD700', '#8A2BE2', '#ADFF2F', '#00BFFF', '#FF8C00', '#48D1CC', '#FF69B4', '#00CED1', '#8B4513', '#7FFF00', '#40E0D0'],
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [
            ],
            labels:{
                rotate: -45,

            },
            title:{
                text: 'Desempeño',
                style:{
                    fontWeight: 200,
                    fontSize: '15px',

                }
            }

        },
        yaxis:{
            title:{
                text: 'Porcentaje alcanzado grados',
                style:{
                    fontWeight: 200,
                    fontSize: '15px',
                }
            },
            labels: {
                show: false,
            }
        },

        fill: {
            opacity: 1
        },
        tooltip: {

        }
    },
    series: [

    ]
};
export default chartData;
