import {SET_ROLES,SET_MENTOR,SET_DATE_FILTER,SET_DATE_INSTITUTION_FILTER} from "../actions/type";
import {UserInterface} from "../../utils/user";
export interface AppState {
    role:string,
    mentor:{
        user:UserInterface
    }
    degree:{
        id: number,
        name: string,
    },
    institution:{
        name: "",
        id: 0,
    }
}
export const initialState: AppState = {
    role: '',
    mentor: {
        user:{
            roles: '',
            username: '',
            id: 0,
        }
    },
    degree:{
        id: 0,
        name: '',
    },
    institution:{
        name: "",
        id: 0,
    }

};

export const DataRoles = (state = initialState, action:any)=>{
    switch (action.type) {
        case SET_ROLES:
            return {
                role: action.payload,
            };
        case SET_MENTOR:
            return {
                ...state,
                mentor: action.payload,
            };
        case SET_DATE_FILTER:
            return {
                ...state,
                degree: action.degree,
            };
        case SET_DATE_INSTITUTION_FILTER:
            return {
                ...state,
                institution: action.institution,
            };
        default:
            return state;

    }
};