import * as React from "react";
import * as ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";
import config from "./config";
import "./assets/scss/style.scss";
import * as dotenv from "dotenv";
import {Toaster} from "react-hot-toast";

dotenv.config();

const app = (
  <React.StrictMode>
      <Toaster
          position="bottom-left"
          reverseOrder={false}
      />
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
ReactDOM.render(app, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
