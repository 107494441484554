// @flow
import * as React from 'react';
import ICLHOVER from "../../../assets/Xuaii/Individual/area press.png";
import ICLG from "../../../assets/Xuaii/Individual/area off.png";
import IPMHOVER from "../../../assets/Xuaii/Individual/competencia press.png";
import IPMG from "../../../assets/Xuaii/Individual/competencia off.png";
import imgEnglisEnable from "../../../assets/Xuaii/Individual/ingles press.png";
import imgEnglisDisable from "../../../assets/Xuaii/Individual/ingles deshabilitado_1.png";
import {useState} from "react";
import 'src/Style/Eva/Xuaii/OptionsFilter.scss';
import Excel from "../../utils/Excel";
import DownloadPDF2 from '../../utils/DownloadPDF2';
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useSelector} from "../../../store/reducer";
interface Props_nav{
    style: string
    setLevel?: React.SetStateAction<any>
    optionsWorld?:React.SetStateAction<any>
    medal?:string
    setButtonBool1?:React.SetStateAction<any>
    english: boolean
    year ?:React.SetStateAction<any>
    value?:React.SetStateAction<any>
    campusName?: string
    studentId?: number
    buttonBool1?: React.SetStateAction<any>
    dataStudentReport?: any;
    nameStudent?: any;
}

let medalBool1 = false;
let medalBool2 = false;
const OptionsFilter = ({ style,setButtonBool1,english,year,value,campusName, studentId, buttonBool1, nameStudent}: Props_nav) => {
    const[imgBool,setImgBool] = useState(0);
    let AreaSection = ICLHOVER;
    let CompetencySection = IPMG;
    let location = useLocation();
    const test = useSelector((state) => state.studentXuaiiR)
    const dispatch = useDispatch();
    const HandlerButtonEnable=(e:number)=>{
        setButtonBool1(e);
    }
    return (
        <>
            {campusName !== 'Todas las sedes' &&
                <nav className='Left-Nav' style={{}}>
                    {

                    }
                    <section className="Left-Nav__section-one">
                        <button className="Left-Nav__section-one__primary-button"
                                onClick={() => {
                                    setImgBool(0)
                                    HandlerButtonEnable(0)
                                }}
                        >
                            <img className='Left-Nav__section-one__primary-button__img'
                                 src={imgBool == 0 ? ICLHOVER : ICLG}
                                 alt="" id="img-CL"/>
                        </button>
                        <button className="Left-Nav__section-one__primary-button"
                                onClick={() => {
                                    setImgBool(1)
                                    HandlerButtonEnable(1)
                                }}
                        >
                            <img className='Left-Nav__section-one__primary-button__img'
                                 src={imgBool == 1 ? IPMHOVER : IPMG}
                                 alt="" id="img-PM"/>
                        </button>
                        {!english ||
                            <button className="Left-Nav__section-one__primary-button"
                                    onClick={() => {
                                        setImgBool(2)
                                        HandlerButtonEnable(2)
                                    }}
                            >
                                <img className='Left-Nav__section-one__primary-button__img'
                                     src={imgBool == 2 ? imgEnglisEnable : imgEnglisDisable} alt="" id="img-PM"/>
                            </button>
                        }
                    </section>
                    <br/>
                    {
                        /*
                        <button className="Left-Nav__section-one__primary-button"
                        >
                            <img className='Left-Nav__section-one__primary-button__img' src={buttonExcel}
                                 alt="" id="img-PM"/>
                        </button>

                         */
                    }
                    {
                        location.pathname === '/teacher/tribes' &&
                        <Excel
                            value={value}
                            year={year}
                        />
                    }
                    <br/>
                    {
                        location.pathname === '/teacher/nomands' &&

                        <DownloadPDF2 nameStudent={nameStudent} campusName={campusName} studentId={studentId}/>
                    }
                    <br/>
                    {
                        /*
                        <button className="Left-Nav__section-one__primary-button"
                        >
                            <img className='Left-Nav__section-one__primary-button__img' src={buttonPdf}
                                 alt="" id="img-PM"/>
                        </button>

                         */
                    }
                </nav>
            }
        </>
    );
};

export default OptionsFilter;