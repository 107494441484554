
import axios from "axios";
import { getToken } from "./user";
import * as configEnv from "dotenv";

configEnv.config();

const apiAuth = axios.create({
  baseURL: "https://api.selecu.net/api-selecu/",
  headers: { "access-token": getToken() },
});

const api = axios.create({
  baseURL: "https://api.selecu.net/api-selecu/",
  // enabling CORS
});
const apiLocal = axios.create({
  baseURL: "http://localhost:3001/",
  // enabling CORS
});
const apiSultan = axios.create({
  baseURL: "https://api.selecu.net/sultan/",
  // enabling CORS
});
const apiSultanLocal = axios.create({
  baseURL: "http://localhost:3005/",
  // enabling CORS
});



const BaseApi = axios.create({
  baseURL: "https://api.selecu.net/products/",
});
const apiEva= axios.create({
  baseURL: "https://api.selecu.net/eva-web/"
});

const apiEvaLocal= axios.create({
  baseURL: "http://localhost:3004/"
});
const apiHost = axios.create({
  baseURL: "http://localhost:3001/",
  headers: { "access-token": getToken() },
  // enabling CORS
});

export { api, apiAuth, apiHost,BaseApi,apiEva, apiEvaLocal, apiLocal, apiSultanLocal, apiSultan };

