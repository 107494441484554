import { combineReducers } from 'redux';
import { createSelectorHook } from 'react-redux';
import ableReducer, { initialState as AbleState } from './ableReducer';
import demoReducer, { initialState as DemoState } from './demoReducer';
import {studentXuaiiReducer,initialState as studentXuaiiRInitial } from '../redux/reducer/XuaiiStudents';
import {studentDrakeReducer,initialState as studentDrakeInitial } from '../redux/reducer/DrakeStudents';
import {GroupXuaiiReducer,initialState as GroupXuaiiRInitial  } from '../redux/reducer/XuaiiGroup';
import {XuaiiCoordiReducer,initialState as XuaiiCoordiInitial  } from '../redux/reducer/XuaiiCoordi';
import {DataRoles,initialState as DataRolesInitial} from '../redux/reducer/DataRoles';
import {Products, initialState as ProductInitial} from "../redux/reducer/Products";
import {SultanCoordinadorReducer, initialState as SultanCoordinadorInitial} from "../redux/reducer/SultanCoordinador";
import {GroupSultanReducer, initialState as SultanGroupInitial} from "../redux/reducer/SultanGroup";

const reducer = combineReducers({
    able: ableReducer,
    demo: demoReducer,
    studentXuaiiR:studentXuaiiReducer,
    studentDrakeR:studentDrakeReducer,
    GroupXuaiiReducer:GroupXuaiiReducer,
    XuaiiCoordiReducer:XuaiiCoordiReducer,
    SultanCoordinadorReducer: SultanCoordinadorReducer,
    SultanGroupReducer: GroupSultanReducer,
    DataRoles:DataRoles,
    Product: Products,
});

export const useSelector = createSelectorHook<{
    able: typeof AbleState;
    demo: typeof DemoState;
    studentXuaiiR: typeof studentXuaiiRInitial;
    GroupXuaiiReducer: typeof GroupXuaiiRInitial;
    XuaiiCoordiReducer: typeof XuaiiCoordiInitial;
    SultanCoordinadorReducer: typeof SultanCoordinadorInitial;
    SultanGroupReducer: typeof SultanGroupInitial;
    DataRoles: typeof DataRolesInitial;
    Product: typeof ProductInitial;
    studentDrake: typeof studentDrakeInitial
}>();

export default reducer;
