import {useEffect, useState} from "react";

const UseCurrentYear = () => {
    const [initialYear,setInitialYear] = useState<number>(0);
    const [currentYear,setCurrentYear] = useState<number[]>([0]);

    useEffect(()=>{
        const fechaActual = new Date();
        setInitialYear(fechaActual.getFullYear());
    },[])
    useEffect(()=>{

        let beforeYear = 2024;
        const restYear = (initialYear - beforeYear);
        const years = [];
        let t =0;
        for (let i = 0; i < restYear +1; i++) {
          years.push(beforeYear++);
        }
        setCurrentYear(years);

    },[initialYear])

    return {
        currentYear
    }

};

export default UseCurrentYear;