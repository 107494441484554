import {SET_PRODUCT} from "../actions/type";
import {UserInterface} from "../../utils/user";
export interface AppState {
    product:string,
}
export const initialState: AppState = {
    product:'',
};

export const Products = (state = initialState, action:any)=>{
    switch (action.type) {
        case SET_PRODUCT:
            return {
                product: action.payload,
            };
        default:
            return state;

    }
};