import {
    SET_AREA_GROUP,
    SET_AREA_GROUP_SULTAN,
    SET_COMPETENCY_GROUP,
    SET_ENGLISH_GROUP,
    SET_GENERAL_COORDINADOR_SULTAN
} from "../actions/type";
// hola
import {
    AreaDataByGradeSultan,
    AreaDataXuaiiGrupal,
    CompetencyDataXuaiiGrupal,
    worldviewXuaii
} from "../../views/xuaii/types";
import {AreaDataSultanGrupal} from "../../views/El Engaño del Sultán/Tribes/Shared/Interfaces/data.interfaces";

export interface TypeGroupFilter {
    [key:string]:[AreaDataXuaiiGrupal],
}

export interface TypeGroupFilterCompetency {
    [key:string]:[CompetencyDataXuaiiGrupal],
}
export interface TypeGroupFilterEnglish {
    [key:string]:worldviewXuaii,
}

export interface TypeGrouptId {
    [key:number]:TypeGroupFilter
}
export interface TypeGroupIdCompetency{
    [key:number]:TypeGroupFilterCompetency
}
export interface TypeGroupIdEnglish{
    [key:number]:TypeGroupFilterEnglish
}



export interface AppState {
    areaGroupSultan: {
        [groupId: number]: {
            sultan: 'Sultan';
            year: number;
            data: [AreaDataSultanGrupal];
        };
    };

    competencyGroup:{
        [key: number]: TypeGroupIdCompetency;
    };
    englishGroup:{
        [key: number]: TypeGroupIdEnglish;
    }
}

export const initialState: AppState = {
    areaGroupSultan:{},
    competencyGroup:{},
    englishGroup:{}
};


export const GroupSultanReducer = (state = initialState, action:any)=>{
    switch (action.type) {
        case SET_AREA_GROUP_SULTAN:
            return {
                ...state,
                areaGroupSultan: {
                    ...state.areaGroupSultan,
                    [action.groupId]: {
                        sultan: 'Sultan',
                        year: action.year,
                        data: action.payload
                    }
                },
            };
        case SET_COMPETENCY_GROUP:
            return {
                ...state,
                competencyGroup: {
                    ...state.competencyGroup,
                    [action.identifier]: {
                        ...state.competencyGroup[action.identifier],
                        [action.year]: {
                            ...(state.competencyGroup[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        case SET_ENGLISH_GROUP:
            return {
                ...state,
                englishGroup: {
                    ...state.englishGroup,
                    [action.identifier]: {
                        ...state.englishGroup[action.identifier],
                        [action.year]: {
                            ...(state.englishGroup[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        default:
            return state;
    }
};
