import * as React from 'react';
import SelectProduct from "./views/Select Product/SelectProduct";
const Signin1 = React.lazy(() => import('./views/Authentication/SignIn/SignIn1'));

export interface RouteObject {
    path: string;
    exact?: boolean;
    name?: string;
    component: any;
}
const route: RouteObject[] = [
    { path: '/login', exact: true, name: 'login', component: Signin1 },
    { path: '/select-product', exact: true, name: 'Select product', component: SelectProduct },

    // { path: '/auth/signup-2', exact: true, name: 'Signup 2', component: SignUp2 },
    // { path: '/auth/signin-1', exact: true, name: 'Signin 1', component: Signin1 },
    // { path: '/auth/signin-2', exact: true, name: 'Signin 2', component: Signin2 },
];
export default route;
