// @flow
import * as React from 'react';
import ContenLoader from "react-content-loader";


const Download = () => {
    return (
        <ContenLoader
            speed={2}
            width="100%"
            height="43"
            backgroundColor="#C2D0E9"
            foregroundColor="#ffffff"
            style={{maxWidth: '100%',borderRadius:6}}
        >
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="100" />
        </ContenLoader>
    );
};

export default Download;