import React, { useState, useEffect } from 'react';
import { apiAuth } from '../../utils/api';
import { getUser } from '../../utils/user';
// import { useNavigate } from 'react-router-dom';

import XuaiiOn from '../../assets/Select Product/Productos/XuaiiOn.png';
import SultanOn from '../../assets/Select Product/Productos/SultánOn.png';
import ComparativoOn from '../../assets/Select Product/Productos/ComparativoOn.png';
import DrakeOn from '../../assets/Select Product/Productos/BuscandoTesoroOn.png';
import ExpedicionOn from '../../assets/Select Product/Productos/Expedición cerebro.png';
import VelozmenteOn from '../../assets/Select Product/Productos/VelozmenteOn.png';
import DeeperLearning from '../../assets/Select Product/Productos/Deeper learning.png'
import Descubre from '../../assets/Select Product/Productos/Descubre comfama.png'
import Informes from '../../assets/Select Product/Productos/Informes generales.png'
import JuegosComfama from '../../assets/Select Product/Productos/Juegos Comfama.png'
import PIHE from '../../assets/Select Product/Productos/PIHE.png'
import Tipo from '../../assets/Select Product/Productos/Que tipo.png'


import DefaultProduct from '../../assets/images/user/user-empty.jpg';

import "../../Style/Eva/Select product/SelectProduct.scss"
import LoadingSpinner from "../Comparativo/Components/LoadingSpinner";
import { useDispatch } from "react-redux";
import { SetProduct } from "../../redux/actions";

interface Product {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

const ProductSelection: React.FC = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const productImages: { [key: string]: string } = {
        'Buscando a Xuaii': XuaiiOn,
        'El Engaño del Sultán': SultanOn,
        'Expedicion Cerebro': ExpedicionOn,
        'Buscando a Drake': DrakeOn,
        'Velozmente': VelozmenteOn,
        'Comparativo': ComparativoOn,
        'Informes generales': Informes,
        'Deeper Learning': DeeperLearning,
        'Que tipo de Jugador Eres': Tipo,
        'EExperiencias Comfama': JuegosComfama,
        'PIHE': PIHE
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const user = getUser();

                if (!user || !user.id) {
                    setError('No se ha encontrado información de usuario');
                    setLoading(false);
                    return;
                }

                const response = await apiAuth.get(`user/products/${user.id}`);

                if (response.data && response.data.length > 0) {
                    const sortedProducts = response.data.sort((a: any, b: any) =>
                        a.name.localeCompare(b.name)
                    );
                    setProducts(sortedProducts);
                } else {
                    setError('No se encontraron productos para este usuario');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
                setError('');
                window.location.reload()
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const handleProductSelect = (productName: string) => {
        // Set product in localStorage for persistent storage
        localStorage.setItem('selectedProduct', productName);


        // Set product in cookie for compatibility with existing code
        document.cookie = `t=${(productName)}`;

        // Dispatch product to Redux store
        dispatch(SetProduct(productName));

        // Navigate based on product
        if (productName === 'Comparativo') {
            window.location.href = '/tablero/Comparativo';
        } else {
            window.location.href = '/teacher/dashboard';
        }
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="product-wrapper">
            <div className="product-tittle">
                <h2 style={{color: "white"}} className="product-tittle">¿Qué reporte quieres consultar?</h2>
            </div>
            <div className="product-content">
                {products.map((product) => (
                    <button
                        key={product.id}
                        onClick={() => handleProductSelect(product.name)}
                        className="product-button"
                    >
                        {productImages[product.name] ? (
                            <img src={productImages[product.name]} alt={product.name}/>
                        ) : (
                            <img src={DefaultProduct} alt="Default Product"/>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ProductSelection;