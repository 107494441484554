// @flow
import {useEffect, useState} from "react";
import {Card, Col} from "react-bootstrap";
import Chart from "react-apexcharts";
import bar from "../../Chart/chartSultanAreGroup";
import barWorldView from "../../Chart/worldViewSultan";
import * as React from "react";
import {AreaDataByGradeSultanDirectivo, TypeAllCampus, worldviewXuaii} from "../../../xuaii/types";
import {apiEva, apiSultan, apiSultanLocal} from "../../../../utils/api";


let objetComplet = {
    'Ciencias naturales' : [],
    'Competencias niudadanas' : [],
    'Lenguaje':[],
    'Matematicas':[],
    'Inglés':[]
};
interface TypeFilter {
    year: React.SetStateAction<any>
}
const DirectiveAllCampus = ({year}:TypeFilter) => {

    const [loading,setLoading]= useState<boolean>(true);
    const [loadingText,setLoadingText] = useState('');
    const [dataArea, setDataArea] = useState<AreaDataByGradeSultanDirectivo>({});
    const [area,setArea] = useState({
        options: {
            xaxis: {
                categories: [],
                title:{
                    text: 'Competencia',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                }
            }
        }
    });
    const [series,setSeries] = useState<ApexAxisChartSeries>();

    //worldView
    const [worldView,setWorldView] = useState<worldviewXuaii>();

    const [Listening,setListening] = useState<ApexAxisChartSeries>();
    const [general,setGeneral] = useState<ApexAxisChartSeries>();
    const [reading,setReading] = useState<ApexAxisChartSeries>();
    const [worldviewCategories,setWorldviewCategories] = useState({ options: {
            xaxis: {
                categories: ['A1','A2','B1','B2'],
                title: {
                    text: 'Nivel',
                    style:{
                        fontWeight: 200,
                        fontSize: '15px',

                    }
                },
            },
        },});


    useEffect(()=>{
        setLoading(true);
        setLoadingText('Espera, cargando datos.');

        apiSultan.get("viajeros-api/summary/general/directive/allCampus/"+year).then((response)=>{
            const data = response.data;
            setDataArea(data);
            // dispatch(setGeneralCoordinadorSultan(data,idCampus,year))
            setLoading(false);
        }).catch((error) => {
            setLoading(true);
            setLoadingText('Sin datos disponibles.');
        });

        setLoading(true);
        setLoadingText('Sin datos disponibles.');

        // apiSultan.patch<worldviewXuaii>('/responses-xuaii/all-centers/worldview/performance', params)
        //
        //     .then((response) => {
        //         const data = response.data;
        //         setWorldView(data)
        //     });


    },[year])


    useEffect(() => {
        if (Object.keys(dataArea).length === 0) return;

        const subjects = Array.from(new Set(Object.values(dataArea).map(item => item.topic)));
        const grades = Array.from(new Set(Object.values(dataArea).map(item => item.grade))).sort((a, b) => a - b);
        const categorie: any = [['3'], ['4'], ['5'], ['6'], ['7'], ['8'], ['9'], ['10'], ['11']];
        const seriesData = subjects.map(subject => ({
            name: subject,
            data: grades.map(grade => {
                const item = Object.values(dataArea).find(d => d.grade === grade && d.topic === subject);
                return item ? Math.round(item.averagePercentage) : 0;
            })
        }));
        setArea(() => ({
            options: {
                xaxis: {
                    categories: categorie,
                    title: {
                        text: 'Área',
                        style: {
                            fontWeight: 200,
                            fontSize: '15px',
                        }
                    }
                }
            }
        }));
        setSeries(seriesData);
    }, [dataArea]);


    useEffect(()=>{
        const stateVariables = {
            "listening_and_Vocabulary": setListening,
            "general":setGeneral,
            "reading":setReading,
        };

        for (const key in worldView) {
            let value = worldView[key]
            let series = [];
            let state = stateVariables[key as keyof typeof stateVariables];
            for (const valueKey in value) {
                series.push(value[valueKey])
            }
            state([{name:key,data:series}])
        }
    },[worldView])

    return (
        <div className="" id="">
            {
                series?
                    <Col>
                        <br />
                        <Card className="shadow-none" style={{ maxWidth: 900, margin: "auto" }}>
                            <Card.Header className="text-center"><h5>Porcentaje alcanzado todas las sedes</h5></Card.Header>
                            <Card.Body>
                                {series.length > 0 ? (
                                    <Chart
                                        {...bar}
                                        series={series}
                                        options={{
                                            ...bar.options,
                                            ...area.options,
                                        }}
                                    />
                                ) : (
                                    <p>Cargando datos...</p>
                                )}
                            </Card.Body>
                        </Card>
                        <br />
                    </Col>
                    :
                    <></>
            }

            <>

                {
                    Listening?
                        <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                            <Card.Header className="text-center"><h5>Listening and Vocabulary</h5></Card.Header>
                            <Card.Body>
                                <Chart
                                    {...barWorldView}
                                    series={Listening}
                                    options={{
                                        ...barWorldView.options,
                                        ...worldviewCategories.options,
                                    }}

                                />
                            </Card.Body>
                        </Card>
                        :<></>

                }
                <br/>
                {
                    reading?

                        <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                            <Card.Header className="text-center"><h5>Reading</h5></Card.Header>
                            <Card.Body>
                                <Chart
                                    {...barWorldView}
                                    series={reading}
                                    options={{
                                        ...barWorldView.options,
                                        ...worldviewCategories.options,
                                    }}

                                />
                            </Card.Body>
                        </Card>:<></>
                }
                <br/>
                {
                    general?
                        <Card  className="shadow-none" style={{maxWidth: 450 , margin:"auto"}}>
                            <Card.Header className="text-center"><h5>General</h5></Card.Header>
                            <Card.Body>
                                <Chart
                                    {...barWorldView}
                                    series={general}
                                    options={{
                                        ...barWorldView.options,
                                        ...worldviewCategories.options,
                                    }}

                                />
                            </Card.Body>
                        </Card>:<></>
                }
            </>:<></>
        </div >
    );
};

export default DirectiveAllCampus;