import Chart from 'react-apexcharts';
type ChartProps = React.ComponentProps<typeof Chart>;

const chartData: ChartProps = {
    height: "350",
    type: 'bar',
    options: {
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '90%',
                borderRadius:10,
                borderRadiusApplication: "end",
            }
        },
        dataLabels: {
            enabled: true,

            style:{
                fontWeight: 600,
                fontSize:'14px',
            }
        },
        colors: ['#FFA403','#ce4d1f',  '#79B0AB'],
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [

            ],
            labels:{
                rotate: -45,

            },
            title:{
                text: 'Desempeño',
                style:{
                    fontWeight: 200,
                    fontSize: '15px',

                }
            }

        },
        yaxis:{
            title:{
                text: 'Cantidad de estudiantes',
                style:{
                    fontWeight: 200,
                    fontSize: '15px',
                }
            },
            labels: {
                show: false,
            }
        },

        fill: {
            opacity: 1
        },
        tooltip: {

        }
    },
    series: [

    ]
};
export default chartData;
