// @flow 
import * as React from 'react';
import CoordinadorXuaiiGroup from "../CoordinadorXuaiiG";
import {Col} from "react-bootstrap";
import {useState} from "react";
import {api} from "../../../../utils/api";
import {getToken, getUser, UserInterface} from "../../../../utils/user";
import {TypeCampus, TypeInstitution} from "../../types";
import DirectiveAllCampus from "../../Componets/DirectiveAllCampus";
import CoordinadorXuaiiGeneral from "../CoordinadorXuaiiGeneral";
import {useSelector} from "../../../../store/reducer";


const DirectiveXuaiiGeneral = () => {
    /*
    //REDUX =====================================
    const Redux = useSelector((state) => state.DataRoles)
    //===========================================

    const [user, setUser] = React.useState<UserInterface>();
    const headers = {
        "access-token": getToken(),
    };
    console.log(Redux);
    React.useEffect(() => {
        const user: UserInterface = getUser();
        setUser(user);
    }, []);

    //INSTITUTION NAME AND ID
    const [institutions, setInstitutions] = useState<[TypeInstitution]>([
        {
            id: 0,
            name: "",
        },
    ]);

    const getInstitutionsOptions = () => {
        if (user?.id) {
            api
                .get("institution/organization/userId/" + user.id, {headers})
                .then((response) => {
                    const data = response.data;
                    setInstitutions(data);
                });
        }
    };
    console.log(institutions);

    React.useEffect(() => {
        if (user) {
            getInstitutionsOptions();
        }
    }, [user]);
    //DESPLEGABLE DIRECTIVO SEDES<================================================


     */
    return (
        <div>
            <CoordinadorXuaiiGeneral/>
            {
                /*
                <CoordinadorXuaiiGroup
                    institutions={institutions}
                />

                 */
            }

        </div>
    );
};

export default DirectiveXuaiiGeneral;