import {
    SET_COMPETENCY_COORDI,
    SET_ENGLISH_COORDI, SET_GENERAL_COORDINADOR_SULTAN
} from "../actions/type";
import {
    AreaDataByGradeSultan,
    AreaDataXuaiiGrupal,
    CompetencyDataXuaiiGrupalCoordi,
    worldviewXuaii
} from "../../views/xuaii/types";

export interface TypeCoordiFilter {
    [key:string]:[AreaDataByGradeSultan],
}

export interface TypeProductSultan {
    [key:string]:['Sultan']
}

export interface TypeCoordiFilterCompetency {
    [key:string]:[CompetencyDataXuaiiGrupalCoordi],
}
export interface TypeCoordiFilterEnglish {
    [key:string]:worldviewXuaii,
}

export interface TypeCoorditId {
    [key:number]:TypeCoordiFilter
}
export interface TypeCoordiIdCompetency{
    [key:number]:TypeCoordiFilterCompetency
}
export interface TypeCoordiIdEnglish{
    [key:number]:TypeCoordiFilterEnglish
}



export interface AppState {
    generalCoordinador: {
        [institutionId: number]: {
            sultan: 'Sultan';
            year: number;
            data: AreaDataByGradeSultan;
        };
    };

    competencyCoordi:{
        [key: number]: TypeCoordiIdCompetency;
    };
    englishCoordi:{
        [key: number]: TypeCoordiIdEnglish;
    }
}

export const initialState: AppState = {
    generalCoordinador:{},
    competencyCoordi:{},
    englishCoordi:{}
};


export const SultanCoordinadorReducer = (state = initialState, action:any)=>{
    switch (action.type) {
        case SET_GENERAL_COORDINADOR_SULTAN:
            return {
                ...state,
                generalCoordinador: {
                    ...state.generalCoordinador,
                    [action.institutionId]: {
                        sultan: 'Sultan',
                        year: action.year,
                        data: action.payload
                    }
                },
            };
        case SET_COMPETENCY_COORDI:
            return {
                ...state,
                competencyCoordi: {
                    ...state.competencyCoordi,
                    [action.identifier]: {
                        ...state.competencyCoordi[action.identifier],
                        [action.year]: {
                            ...(state.competencyCoordi[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        case SET_ENGLISH_COORDI:
            return {
                ...state,
                englishCoordi: {
                    ...state.englishCoordi,
                    [action.identifier]: {
                        ...state.englishCoordi[action.identifier],
                        [action.year]: {
                            ...(state.englishCoordi[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        default:
            return state;
    }
};
