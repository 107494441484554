import React, {useEffect, useState} from "react";
import {getUser, UserInterface} from "../../utils/user";
import {apiAuth} from "../../utils/api";

const useCoordi = ()=>{

    const [user, setUser] = React.useState<UserInterface>();
    const [data, setData] = React.useState({
        "institution": {
            "name": "",
            "id": 0
        },
        "roles":""
    });
    React.useEffect(() => {
        const user: UserInterface = getUser();
        setUser(user);
        apiAuth.get("user/data/" + user.id, {
            headers: {
                "access-token": `${localStorage.getItem("token")}`
            },
        })
            .then((response) => {
                setData(response.data);
            }).catch((err) => {
            console.log(err);
        });
    }, []);
    return{
        user,
        data
    }
}

export default useCoordi;