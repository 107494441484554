import {SET_AREA_GROUP,SET_COMPETENCY_GROUP,SET_ENGLISH_GROUP} from "../actions/type";
import {AreaDataXuaiiGrupal,CompetencyDataXuaiiGrupal,worldviewXuaii} from "../../views/xuaii/types";

export interface TypeGroupFilter {
    [key:string]:[AreaDataXuaiiGrupal],
}

export interface TypeGroupFilterCompetency {
    [key:string]:[CompetencyDataXuaiiGrupal],
}
export interface TypeGroupFilterEnglish {
    [key:string]:worldviewXuaii,
}

export interface TypeGrouptId {
    [key:number]:TypeGroupFilter
}
export interface TypeGroupIdCompetency{
    [key:number]:TypeGroupFilterCompetency
}
export interface TypeGroupIdEnglish{
    [key:number]:TypeGroupFilterEnglish
}



export interface AppState {
    areaGroup: {
        [key: number]: TypeGrouptId;
    };

    competencyGroup:{
        [key: number]: TypeGroupIdCompetency;
    };
    englishGroup:{
        [key: number]: TypeGroupIdEnglish;
    }
}

export const initialState: AppState = {
    areaGroup:{},
    competencyGroup:{},
    englishGroup:{}
};


export const GroupXuaiiReducer = (state = initialState, action:any)=>{
    switch (action.type) {
        case SET_AREA_GROUP:
            return {
                ...state,
                areaGroup: {
                    ...state.areaGroup,
                    [action.identifier]: {
                        ...state.areaGroup[action.identifier],
                        [action.year]: {
                            ...(state.areaGroup[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        case SET_COMPETENCY_GROUP:
            return {
                ...state,
                competencyGroup: {
                    ...state.competencyGroup,
                    [action.identifier]: {
                        ...state.competencyGroup[action.identifier],
                        [action.year]: {
                            ...(state.competencyGroup[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        case SET_ENGLISH_GROUP:
            return {
                ...state,
                englishGroup: {
                    ...state.englishGroup,
                    [action.identifier]: {
                        ...state.englishGroup[action.identifier],
                        [action.year]: {
                            ...(state.englishGroup[action.identifier]?.[action.year] || {}),
                            [action.filter]: action.payload
                        }
                    },
                },
            };
        default:
            return state;
    }
};
