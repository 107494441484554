// @flow 
import * as React from 'react';
// import CoordinadorXuaiiGroup from "../CoordinadorXuaiiG";
// import {Col} from "react-bootstrap";
// import {useState} from "react";
// import {api} from "../../../../utils/api";
// import {getToken, getUser, UserInterface} from "../../../../utils/user";
// import {TypeCampus, TypeInstitution} from "../../types";
// import DirectiveAllCampus from "../../Componets/DirectiveAllCampus";
// import CoordinadorXuaiiGeneral from "../CoordinadorXuaiiGeneral";
// import {useSelector} from "../../../../store/reducer";
import CoordinadorSultanG from "../Tribes/Coordinador/CoordinadorSultanG";
import CoordinadorSultanGeneral from "./CoordinadorSultanGeneral";


const DirectiveSultanGeneral = () => {
    return (
        <div>
            {/*<CoordinadorSultanG/>*/}
            <CoordinadorSultanGeneral isDirective={true}/>
        </div>
    );
};

export default DirectiveSultanGeneral;