// @flow
import * as React from 'react';
import * as XLSX from 'xlsx';
import './Style/Excel.scss';
import {useEffect, useState} from "react";
import {api, apiEva} from "../../utils/api";
import {AreaDataXuaii, competencyDataXuaii, QuestionType,ExcelDataXuaii} from "../xuaii/types";
import CollapseNavFilter from "../Eva/Nomands_Componets/Collapse-Nav-Filter";
import useCoordi from "../Teacher/useCoordi";
import {getToken, getUser, UserInterface} from "../../utils/user";
import {useLocation} from "react-router-dom";
import imgDownload from 'src/assets/NewDesign/04 Contenido/Descargar repoerte off.png';
import Download from "../Loaders/Download";
import buttonExcelHover from 'src/assets/Xuaii/excel hover_1.png';
import buttonExcel from 'src/assets/Xuaii/excel off_1.png';
import {useSelector} from "../../store/reducer";
import buttonPDFHover from "../../assets/Xuaii/pdf hover.png";
import buttonPDF from "../../assets/Xuaii/pdf off_1.png";

interface typeDatos{
    [key: string]: [string]
}

interface TypeParams{
    value:React.SetStateAction<any>
    year:React.SetStateAction<any>
}
const Excel = ({value,year}:TypeParams) => {
    //REDUX =====================================
    const Redux = useSelector((state) => state.DataRoles)
    //===========================================

    const [hoveredButton, setHovered] = useState(false);


    const [data,setData] = useState<[AreaDataXuaii]>([
        {
            topic: "",
            correct_performance: 0,
            incorrect_performance: 0
        }
    ]);

    const headers = {
        "access-token": getToken(),
    };


    /*
    //REACT ROUTER ======================>
    const location: any = useLocation();
    const props: any = location.state;
    //REACT ROUTER <======================

     */

    //BOOL STATE VIEW NOMANDS AND COORDINADOR ==========================================>
    const [individual, setIndividual] = useState(false);

    //STUDENTS =======================================>
    const [nomands, setNomands] = useState([
        {
            user: {
                id: 0,
                fullName: "",
                identification: 0
            },
        },
    ]);

    //USER
    const [user, setUser] = React.useState<UserInterface>();

    //GET STUDENTS =================================================>

    React.useEffect(() => {
        const user: UserInterface = getUser();
        if (Redux.degree?.id)getMentors(Redux.degree?.id);
        else if (user.roles === "Mentor")setUser(user);
    }, []);


    function getMentors(id: number) {
        api
            .get(`/group_mentors_user/mentors/group/${id}`, {headers})
            .then((response) => {
                setUser(response.data[0].user);
            })
            .catch((error) => {
            });
    }

    React.useEffect(()=>{
        if (Redux.degree?.id)getMentors(Redux.degree?.id);
    },[Redux.degree?.id])

    //GET STUDENTS WITH ID MENTORS

    const getStudents = (id: number) => {
        if (user?.id && user?.roles != "Coordinador" && user?.roles != "Directive") {
            api.get("group_mentors_user/get/" + user.id).then((response) => {
                const data = response.data;
                setNomands(data);
            });
        }
    };

    //GET STUDENTS WITH ID GROUP   //PRODUCT=>
    function getGroupById(groupId: number) {
        api
            .get("group_mentors_user/students/" + groupId, {headers})
            .then((response) => {
                setNomands(response.data);
            })
            .catch((error) => {
            });


    }

    React.useEffect(() => {

        if (user) {
            if (user.roles !== "Admin" && user.roles !== "Directive"  && user.roles !== "Coordinador") {
                getStudents(user.id);
            }
            /*
            else if(user?.roles == "Coordinador" && props?.idGroup !== 0 || user?.roles == "Directive" && props?.idGroup !== 0){
                getGroupById(props?.idGroup);
            }

             */
        }
    }, [user]);


    //GET STUDENTS <=================================================

    const [datosExcel,setDatosExcel] = React.useState<typeDatos>({});

    const [finalDatosExcel,setFinalDatosExcel] = React.useState<[ExcelDataXuaii]>([[]]);
    const [namesDatas,setNamesDatas]= React.useState<string[]>();


    //loading
    const [loading,setLoading] = useState(false);

    const GenerarDatos = ()=>{


        setLoading(true)
        obtenerDatos(nomands)
            .then((resultado) => {
                const t = resultado;
                setDatosExcel(t);
            })
            .catch((error) => {
                console.error(error);
            });
    }
    useEffect(()=>{
        const newArray = datosExcel;
        const names:string[] = [];

        const DatosExcelFinal = [];
        for (const item in newArray) {

            let value = newArray[item];

            value.forEach((data,index)=>{
                const parts = data.split('-');
                names.push(parts[1]);
                newArray[item][index] = parts[0];
            })

        }

        const finalNameDatas  = [...new Set(names)];

        setNamesDatas(finalNameDatas);

        const FinalDate:any = Object.values(newArray)


        if (names.length > 0){
            const worksheet = XLSX.utils.json_to_sheet(FinalDate);
            worksheet.A1 = { t: 's', v: 'Nombre' };
            worksheet.B1 = { t: 's', v: names[1] };
            worksheet.C1 = { t: 's', v: names[2] };
            worksheet.D1 = { t: 's', v: names[3] };
            worksheet.E1 = { t: 's', v: names[4] };
            worksheet.F1 = { t: 's', v: names[5] };
            worksheet.G1 = { t: 's', v: names[6] };
            worksheet.H1 = { t: 's', v: names[7] };
            worksheet.I1 = { t: 's', v: names[8] };
            worksheet.J1 = { t: 's', v: names[9] };
            worksheet.K1 = { t: 's', v: names[10] };
            worksheet.L1 = { t: 's', v: names[11] };
            worksheet.M1 = { t: 's', v: names[12] };
            worksheet.N1 = { t: 's', v: names[13] };
            worksheet.O1 = { t: 's', v: names[14] };
            worksheet.P1 = { t: 's', v: names[15] };
            worksheet.Q1 = { t: 's', v: names[16] };
            worksheet.R1 = { t: 's', v: names[17] };
            worksheet.S1 = { t: 's', v: names[18] };
            worksheet.T1 = { t: 's', v: names[19] };
            worksheet.U1 = { t: 's', v: names[20] };
            worksheet.V1 = { t: 's', v: names[21] };
            worksheet.W1 = { t: 's', v: names[22] };
            worksheet.X1 = { t: 's', v: names[23] };
            worksheet.Y1 = { t: 's', v: names[24] };
            worksheet.Z1 = { t: 's', v: names[25] };

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, worksheet, 'Estudiantes');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);


            const a = document.createElement('a');
            a.href = url;
            a.download = `Informe.xlsx`;
            a.click();
            setLoading(false);
            URL.revokeObjectURL(url);
        }


    },[datosExcel])


    const obtenerDatos = async (nomands:any) => {


        const agrupadoPorArea = await nomands.reduce(async (acumuladorPromise:any, objeto:any) => {

            const params = {
                userId:  objeto.user.id,
                type:value,
                year:year
            }

            const acumulador = await acumuladorPromise;

            let name = objeto.user.fullName;

            if (!acumulador[name]) {
                acumulador[name] = [];
            }

            acumulador[name].push(objeto.user.fullName);

            try {
                let response1:any = [];

                await apiEva.patch<[AreaDataXuaii]>('responses-xuaii/user/topic/performance/',params)
                    .then((response)=>{
                        response1 = response.data;
                    });

                const data1 = response1.sort((a:any, b:any) => a.topic.localeCompare(b.topic));

                for (const datum of data1) {
                    acumulador[name].push(`${datum?.correct_performance}%-${datum?.topic}`);
                }
            }catch (error){

            }

            try {
                const response2 = await apiEva.patch<[competencyDataXuaii]>('responses-xuaii/user/competency/performance/',params);
                const data2 = response2.data.sort((a, b) => {
                    const keyA = Object.keys(a)[0];
                    const keyB = Object.keys(b)[0];
                    return keyA.localeCompare(keyB);
                });

                for (const datum of data2) {
                    for (const datumKey in datum) {
                        const value = datum[datumKey];

                        for (const valueKey in value) {
                            let i = value[valueKey].correct_performance;
                            acumulador[name].push(`${Math.round(i)}%-${value[valueKey].area}_${datumKey}`);
                        }
                    }
                }
            }catch (error){

            }

            return acumulador;
        }, {});

        return agrupadoPorArea;
    };

    return (
        <div>

            {

                <div style={{textAlign: "center"}}
                >
                    <button className="download-excel"
                            onClick={() =>  GenerarDatos()}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}>
                      <>
                          {
                              loading && <Download />
                          }
                      </>
                        {
                            !loading && <img src={hoveredButton ? buttonExcelHover : buttonExcel} alt="" className="Button-download-excel" />
                        }
                    </button>
                </div>
            }
        </div>

    );
};

export default Excel;